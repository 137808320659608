import academy_ur from '../images/card-academy-up-right.png'
import academy_ul from '../images/card-academy-up-left.png'
import hub_ur from '../images/card-hub-up-right.png'
import hub_ul from '../images/card-hub-up-left.png'
import statistics_ur from '../images/card-registro-ejercicio-up-right.png'
import statistics_ul from '../images/card-registro-ejercicio-up-left.png'
import shop_ur from '../images/card-tienda-web-up-right.png'
import shop_ul from '../images/card-tienda-web-up-left.png'
import dashboards_ur from '../images/card-dashboards-up-right.png'
import dashboards_ul from '../images/card-dashboards-up-left.png'

const servicesInMaintenance = process.env.REACT_APP_SERVICE_MAINTENANCE || ''

export const cardConfig = {
	VrAcademy: {
		service: 'academy',
		link:
			'https://vracademy.ludusglobal.com/acceso.php?redir=%2Fcampus%2Findex.php',
		bgImage: [academy_ul, academy_ur],
		title: 'title_VR_academy_card',
		subtitle: 'subtitle_VR_academy_card',
		text: 'text_VR_academy_card',
		isInMaintenance: servicesInMaintenance.includes('academy'),
	},
	LRS: {
		service: 'lrs',
		link: '',
		bgImage: [statistics_ul, statistics_ur],
		title: 'title_Statistics_card',
		subtitle: 'subtitle_Statistics_card',
		text: 'text_Statistics_card',
		isInMaintenance: servicesInMaintenance.includes('lrs'),
	},
	Dashboards: {
		service: 'dashboards',
		link: '/dashboards',
		bgImage: [dashboards_ul, dashboards_ur],
		title: 'title_LRS_card_v3',
		subtitle: 'subtitle_LRS_card_v3',
		text: 'text_LRS_card_v3',
		isInMaintenance: servicesInMaintenance.includes('dashboards'),
	},
	Shop: {
		service: 'shop',
		link: 'https://shop.ludusglobal.com/mi-cuenta',
		bgImage: [shop_ul, shop_ur],
		title: 'title_shop_card',
		subtitle: 'subtitle_shop_card',
		text: 'text_shop_card',
		isInMaintenance: servicesInMaintenance.includes('shop'),
	},
	HUB: {
		service: 'hub',
		link: null,
		bgImage: [hub_ul, hub_ur],
		title: 'title_HUB_card',
		subtitle: 'subtitle_HUB_card',
		text: 'text_HUB_card',
		isInMaintenance: servicesInMaintenance.includes('hub'),
	},
}
