import MainWrapper from '../components/common/MainWrapper'
import { Box } from '@mui/material'

const LrsDashboards = () => {
    return (
        <MainWrapper>
            <Box
                sx={{
                    position: 'absolute',
                    top: '65px',
                    left: 0,
                    width: '100vw',
                    height: 'calc(100vh - 65px)',
                    overflow: 'hidden',
                    background: 'transparent'
                }}
            >
                <iframe
                    title="Dashboards"
                    src={process.env.REACT_APP_POWERBI_URL}
                    allowFullScreen={true}
                    allowTransparency={true}
                    style={{
                        border: 'none',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'transparent !important'
                    }}
                />
            </Box>
        </MainWrapper>
    )
}

export default LrsDashboards
