import { FC, useContext, useEffect, useState } from 'react'
import {
	Box,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemText,
	drawerClasses,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { drawerWidth, drawerWidthTablet } from '../../ludusTheme.d'
import { Link, useNavigate } from 'react-router-dom'
import useLaunchHUB from '../../hooks/useLaunchHub'
import { useCookies } from 'react-cookie'
import { useSuccessMessageContext } from '../../../context/successMessageContext'
import { useErrorMessageContext } from '../../../context/errorMessageContext'
import { LanguageContext } from '../../../context/languageContext'
import { useAuthContext } from '../../../context/authContext'
import useChargeBeeLoginLink from '../../hooks/useChargeBeeLogin'
import { useStatusContext } from '../../../context/statusContext'
import { useNavigation } from '../../../context/navigationContext'
import useSetAcademyUrlLogin from '../../hooks/useSetAcademyUrlLogin'
import { useVersion } from '../../../context/appVersionContext'
import { cardConfig } from '../../utils/cardConfig'
import useSetLRSCookie from '../../hooks/useSetLRSCookie'

type MenuNavBarProps = {
	variant?: 'permanent' | 'persistent' | 'temporary'
	open: boolean
	onClose: () => void
}

const MenuNavBar = (props: MenuNavBarProps) => {
	const navigate = useNavigate()
	const { addSuccess } = useSuccessMessageContext()
	const { addError } = useErrorMessageContext()
	const { status } = useStatusContext()
	const { appVersion } = useVersion()
	const [cookies, setCookie, removeCookie] = useCookies()
	const { navigateToStatistics, navigateToServices, navigateToDashboards } = useNavigation()
	const { variant, open, onClose } = props
	const isInDashboards = window.location.href.includes('dashboards')
	const isInStatistics = window.location.href.includes('statistics')

	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)
	const { user } = useAuthContext()
	const { handleLaunchHUB } = useLaunchHUB(
		user,
		language,
		addSuccess,
		addError,
		translate
	)

	const [academy, setAcademy] = useState({
		loginURL: '',
	})

	// useEffect(() => {
	// 	useSetLRSCookie(user, setCookie, addError, translate)
	// }, [user])

	useEffect(() => {
		if (user.role.services.VrAcademy) {
			useSetAcademyUrlLogin(user, status, setAcademy, addError, translate)
		}
	}, [user])

	const handleServices = () => {
		return navigateToServices(appVersion.path, language)
	}

	const handleStatistics = () => {
		return navigateToStatistics(appVersion.path, language)
	}

	const handleDashboards = () => {
		return navigateToDashboards(appVersion.path, language)
	}

	const { fetchLoginLink } = useChargeBeeLoginLink(
		status,
		user,
		addError,
		translate
	)

	const handleChargeBeeClick = async () => {
		try {
			const chargeBeeLink = await fetchLoginLink()
			window.open(chargeBeeLink, '_blank')
		} catch (error) {
			addError(translate('E21125'))
		}
	}

	return (
		<Drawer
			sx={{
				flexShrink: 0,
				[`& .${drawerClasses.paper}`]: {
					width: {
						xs: '100%',
						sm: '100%',
						md: drawerWidthTablet,
						lg: drawerWidth,
						xl: drawerWidth,
					},
					height: '100%',
					overflowX: 'hidden',
				},
			}}
			variant={variant}
			anchor="left"
			open={open}
			onClose={props.onClose ? props.onClose : null}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					padding: '16px',
					bgcolor: 'background.paper',
				}}
			>
				<Box>
					<MenuIcon />
				</Box>
				<IconButton
					aria-label="close"
					color="primary"
					sx={{
						right: 16,
						zIndex: 10,
					}}
					onClick={onClose}
					id="closeMenu"
				>
					<CloseIcon sx={{ fontSize: 45 }} />
				</IconButton>
			</Box>
			<Box
				sx={{
					width: '100%',
					bgcolor: 'background.paper',
					marginLeft: 0,
					display: 'flex',
					flexDirection: 'column',
					flexWrap: 'nowrap',
					justifyContent: 'center',
					alignItems: 'left',
					alignContent: 'stretch',
				}}
			>
				<List
					sx={{
						marginLeft: 3,
						'& .MuiTypography-root': {
							'&:hover': {
								color: '#575757',
								backgroundColor: 'transparent',
							},
							color: 'black',
							fontSize: '20px',
							fontWeight: 400,
							fontFamily: 'Barlow Condensed !important',
						},
						'& .MuiListItem-root': {
							cursor: 'pointer',
							'&:focus, &:active': {
								backgroundColor: 'transparent',
								color: 'inherit',
							},
						},
					}}
				>
					{(isInDashboards || isInStatistics) && (
						<ListItem onClick={handleServices}>
							<ListItemText primary={translate('button_back_to_Portal')} />
						</ListItem>
					)}
					{Object.entries(user.role.services).map(([key, value]) => {
						if (!value) return null
						if (isInDashboards && key === 'Dashboards') return null
						if (isInStatistics && key === 'LRS') return null

						switch (key) {
							case 'LRS':
								return (
									<ListItem key={key} onClick={handleStatistics}>
										<ListItemText primary={translate('button_go_to_statistics')} />
									</ListItem>
								)
							case 'Dashboards':
								return (
									<ListItem key={key} onClick={handleDashboards}>
										<ListItemText primary={translate('button_go_to_lrs')} />
									</ListItem>
								)
							case 'HUB':
								return (
									<ListItem key={key} onClick={handleLaunchHUB}>
										<ListItemText primary={translate('button_go_to_platform')} />
									</ListItem>
								)
							case 'VrAcademy':
								return (
									<ListItem
										key={key}
										component={Link}
										to={
											academy.loginURL !== ''
												? academy.loginURL
												: 'https://vracademy.ludusglobal.com/acceso.php?redir=%2Fcampus%2F'
										}
										target="_blank"
									>
										<ListItemText primary={translate('button_go_to_academy')} />
									</ListItem>
								)
							case 'Shop':
								return (
									<ListItem key={key} onClick={handleChargeBeeClick}>
										<ListItemText primary={translate('button_go_to_shop')} />
									</ListItem>
								)
							default:
								return null
						}
					})}
				</List>
			</Box>
		</Drawer>
	)
}

export default MenuNavBar
